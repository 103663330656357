import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import HoldsTable from './HoldsTable';
import NewHold from './NewHold';
import Divider from '@material-ui/core/Divider';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { centsToDollar } from '../../utils/formatters';
import InlineLoadingText from '../../components/InlineLoadingText';
import { CountAmountItem } from './CountAmountLineItem';

const styles = makeStyles((theme) => ({
    table: {
        marginTop: '30px',
        width: '85vw',
        marginRight: '5vw'
    },
    summaryContainer: {
        paddingLeft: '50px',
        paddingRight: '50px',
        width: '85vw'
      },
      summaryHeader: {
          textAlign: 'center'
      },
      summaryTotals: {
          paddingTop: '10px',
          paddingBottom: '10px'
      },
      summaryBody: {
        paddingTop: '10px',
        paddingBottom: '10px'
      },
      summary: {
        backgroundColor: theme.palette.grey.main,
        borderRadius: '5px',
        border: 'groove',
        borderColor: 'black',
        borderWidth: '0.5px',
      }
}));

const Holds = ({ selectedDate, autoSend }) => {
    const [holds, setHolds] = useState(null);
    const[holdSummary, setHoldSummary] = useState(null);
    const[holdMetrics, setHoldMetrics] = useState(null);
    const [showNewHold, setShowNewHold] = useState(false);
    const { createErrorNotification } = useNotification();
    const classes = styles();

    useTheme();

    const toggleShowNewHold = () => setShowNewHold(!showNewHold);

    useEffect(() => {
        if (holds === null) {
            LoanService.getHeldLoans().then(response => {
                if (response && response.status === 200 && response.data && JSON.parse(response.data).loansOnHold) {
                    setHolds(JSON.parse(response.data).loansOnHold);
                } else {
                    createErrorNotification("Failed to retrieve loan holds");
                }
            });
            LoanService.getHeldLoanSummary().then(response => {
                if(response && response.status === 200 && response.data && JSON.parse(response.data).loansOnHoldSummary) {
                    setHoldSummary(JSON.parse(response.data).loansOnHoldSummary)
                    setHoldMetrics(JSON.parse(response.data).loansOnHoldSummary[0].paymentHoldMetrics[0])
                } else {
                    createErrorNotification("Failed to retrieve loan holds");
                }
            })
        }
    }, [holds, holdSummary, createErrorNotification]);

    function setValue() {
        let lenderArray = [];

        for(let i = 0; i < holdSummary[0].lenderSummary.length; i++)
        {
            let paymentAmount = 0, fundingAmount = 0, numberOfPayment = 0, numberOfFunding = 0;

            for(let x = 0; x < holdSummary[0].fundingHold.length; x++)
            {
                if(holdSummary[0].lenderSummary[i].id === holdSummary[0].fundingHold[x].lenderID)
                {
                    if(holdSummary[0].fundingHold[x].count > 0)
                    {
                        fundingAmount = Number(holdSummary[0].fundingHold[x].sum);
                        numberOfFunding = Number(holdSummary[0].fundingHold[x].count);
                    }
                }
            }
            for(let x = 0; x < holdSummary[0].paymentHold.length; x++)
            {
                if(holdSummary[0].lenderSummary[i].id === holdSummary[0].paymentHold[x].lenderID)
                {
                    if(holdSummary[0].paymentHold[x].count > 0)
                    {
                        paymentAmount = Number(holdSummary[0].paymentHold[x].sum);
                        numberOfPayment = Number(holdSummary[0].paymentHold[x].count);
                    }
                }
            }
            if(paymentAmount !== 0 || fundingAmount !== 0 || numberOfFunding !== 0 || numberOfPayment !== 0) {

                lenderArray.push({lenderName: holdSummary[0].lenderSummary[i].shortName, fundingAmount: fundingAmount, numberOfFunding: numberOfFunding, paymentAmount: paymentAmount, numberOfPayment: numberOfPayment})
            }
        }
        return lenderArray;
    }

    return holds === null || holdSummary === null ? (
        <InlineLoadingText/>
      ) : (
        <Fragment>
        <Grid container direction="column">
            <Grid item className={classes.summaryContainer}>
                <h3 className={classes.summaryHeader}>Summary</h3>
                <Grid container direction="column" className={classes.summary}>
                    <Grid item>
                        <Grid container justifyContent="space-evenly" className={classes.summaryBody}>
                            <Grid item className={classes.summaryTotals}>
                                <li><b>Total # of holds: </b> {holds.length || 0} </li>
                                <li><b>Total # of payment holds: </b>{setValue().map(row => row.numberOfPayment).reduce((accumulator, currentValue) => accumulator + currentValue, 0)} </li>
                                <li><b>Total # of funding request holds: </b> {setValue().map(row => row.numberOfFunding).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</li>
                                <li><b>Total # of queued pending releases:</b> {holdMetrics.pending} </li>
                            </Grid>
                            <Grid item className={classes.summaryTotals}>
                                <li><b>Total amount held: </b>{centsToDollar(setValue().map(row => row.paymentAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0) + setValue().map(row => row.fundingAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0))} </li>
                                <li><b>Total payment amount held: </b>{centsToDollar(setValue().map(row => row.paymentAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0))}</li>
                                <li><b>Total funding request amount held: </b> {centsToDollar(setValue().map(row => row.fundingAmount).reduce((accumulator, currentValue) => accumulator + currentValue, 0))} </li>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryBody} >
                            {setValue() ? setValue().map((row) => (
                            <Grid item>
                                <p><b><u>{row.lenderName}</u></b></p>
                                <li>Total Payment: {row.paymentAmount ? centsToDollar(row.paymentAmount) : "$0"}</li>
                                <li>Payment Count: {row.numberOfPayment || 0}</li>
                                <li>Total Requested Funding: {row.fundingAmount ? centsToDollar(row.fundingAmount) : "$0"}</li>
                                <li>Funding Request Count: {row.numberOfFunding || 0}</li>
                            </Grid>
                            )) : console.log('There will be null')}
                        </Grid>
                    </Grid>
                    <Divider/>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="space-evenly" direction="row" className={classes.summaryTotals}>
                            <Grid>
                                <CountAmountItem label="NSF count / amount" count={holdMetrics.nsfCount} amount={holdMetrics.nsfAmount} />
                                <CountAmountItem label="S1 count / amount" count={holdMetrics.s1Count} amount={holdMetrics.s1Amount} />
                            </Grid>
                            <Grid>
                                <CountAmountItem label="S2 count / amount" count={holdMetrics.s2Count} amount={holdMetrics.s2Amount} />
                                <CountAmountItem label="S3 count / amount" count={holdMetrics.s3Count} amount={holdMetrics.s3Amount} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                <Grid item className={classes.table}>
                <HoldsTable holds={holds} selectedDate={selectedDate}  autoSend={autoSend} />
            </Grid>
        </Grid>
        <NewHold showNewHold={showNewHold} toggleShowNewHold={toggleShowNewHold} />
    </Fragment>
    )
}

export default Holds;