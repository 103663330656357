import React, { Fragment, memo, useState } from 'react';
import AddHoldRequestButtons from './AddHoldRequestButtons';
import AddMarkAsProcessingButton from './AddMarkAsProcessingButton';
import DeletePaymentsButton from './DeletePaymentsButton';
import BulkReleaseProcessingPaymentsButton from './BulkReleaseProcessingPaymentsButton.js'
import RepullButton from './RepullButton';
import MUIDataTable from 'mui-datatables';
import CustomSearchBar from '../../components/CustomSearchBar';
import EditableTextCell from './EditableTextCell';
import NotifyCheckboxCell from './NotifyCheckboxCell';
import { LoanService } from '../../services';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { centsToDollar, sortDollarColumn, aprToPercent, promosToChips, promoToColor } from '../../utils/formatters';
import { dealerNeedsReviewCell, editableCell, isConciergeCell, isCUPullCell } from '../../utils/cellRenderers';
import { saveTableSession, getTableSession } from '../../utils/sessionHelpers';
import { customTableSearch } from '../../utils/tableHelpers.js';
import { FlagCell, PaymentStageCell, LoanMetricTooltip, LoanTypeIcon, DealerMetricTooltip, CommentPreviewTooltip } from '../../components';
import { useRole } from '../../contexts/RolesContext';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useNotification } from '../../contexts/NotificationContext';
import red from '@material-ui/core/colors/red';
import Chip from '@material-ui/core/Chip';

// TODO: Fix dealer routing numbers
// TODO: Review Dealer IDs why are 0's cut off...? - CSV compare?

// TODO: Notes? Hold Notes?

  const tablifyPayments = payments =>
    payments == null ? null :
    payments.map(payment => {
      return [
        payment.id,
        payment.loanNumber,
        payment.dealer.number,
        payment.dealer.name,
        centsToDollar(payment.amount),
        payment.paymentStage,
        payment.paymentStage,
        payment.flagPayment,
        // payment.paymentType,
        payment.dealer.organization.name,
        payment.status,
        payment.notify,
        payment.commentCount,
        payment.dealer.needsReview,
        payment.dealer.isManuallyFlagged,
        payment.lender.shortName,
        payment.applicant.fullName,
        payment.dealer?.isConcierge,
        payment.notes,
        payment.stagesAlreadyPaid,
        centsToDollar(payment.estimatedProjectAmount),
        centsToDollar(payment.totalLoanAmount),
        payment.termMonths,
        aprToPercent(payment.apr),
        payment.otherStagesPaidToday,
        (payment.comingOffHold ? "Yes" : "No"),
        payment.holdComment,
        payment.dealer?.manualFlaggedReason,
        payment.dealer?.totalFundedLoans || 0,
        payment.numberOfPaymentStages,
        promosToChips(payment),
        payment.projectType,
        payment.bounced,
        payment.loanType,
        payment.dealer?.maxLoanAmount,
        payment.dealer?.totalLoanAmount,
        payment.dealer?.averageLoanAmount,
        payment.dealer?.firstFundingDate,
        payment.dealer?.lastFundingDate,
        payment.dealer?.numberOfDelinquentLoans,
        payment.dealer?.numberOfOverThirtyDayDelinquentLoans,
        payment.comments,
        payment.multiplePayments
      ];
    });

  const PaymentsTable = ({ payments, selectedDate, toggleShowNewPayment, paymentType }) => {
    const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { isDailyProcessingWrite, isAdmin } = useRole();
    const starterColumns = ['Loan ID', 'Dealer #', 'Dealer', 'Amount', 'Payment Stage', 'Completed Payments', 'CU Pull', 'Org', 'Status', 'Comments', 'Dealer Account Flag', 'Manual Flag', 'Lender', 'Notes'];
    const [visibleColumns, setVisibleColumns] = useState((sessionStorage.getItem("paymentsTable")) ? getTableSession("paymentsTable") : saveTableSession(starterColumns, "paymentsTable"));
    const { createErrorNotification } = useNotification();
    const [matchExactText, setmatchExactText] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(50);

      const handleMatchExactText = (event) => {
        setmatchExactText(event.target.checked);
      }

      const handleAddPaymentClick = () => {
        toggleShowNewPayment();
      }

      const handlePullClick = () => {
        LoanService.pullPayments(selectedDate).then(response => {
          // !FIXME: Do this better!
          setTimeout(() => window.location.reload(), 500);
        });
        toggleConfirmation();
      };

      const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation);
      }

      const columns = [
        {
          name: 'Payment ID',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
            name: 'Loan ID',
            options: {
                filter: false,
                display: visibleColumns ? visibleColumns.includes('Loan ID') : true,
                customBodyRender: (value, tableMeta) => {
                  return <LoanMetricTooltip
                            loanId={value}
                            estimatedProjectAmount={tableMeta.rowData[19]}
                            totalLoanAmount={tableMeta.rowData[20]}
                            loanTerm={tableMeta.rowData[21]}
                            loanApr={tableMeta.rowData[22]}
                            promos={tableMeta.rowData[29]}
                            projectType={tableMeta.rowData[30]}
                          />
                }
            }
        },
        {
          name: 'Dealer #',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Dealer #') : true,
            customBodyRender: (value, tableMeta) => {
              return <a href={`/dealers/${value}`}>{value}</a>
            }
          }
        },
        {
          name: 'Dealer',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer') : true,
            customBodyRender: (value, tableMeta) => {
                return <DealerMetricTooltip 
                          dealerName={value}
                          totalFundedLoans={tableMeta.rowData[27]} 
                          maxLoanAmount={centsToDollar(tableMeta.rowData[33])}
                          totalLoanAmount={centsToDollar(tableMeta.rowData[34])}
                          averageLoanAmount={centsToDollar(tableMeta.rowData[35])}
                          firstFundingDate={tableMeta.rowData[36]}
                          lastFundingDate={tableMeta.rowData[37]}
                          dealerConcierge={tableMeta.rowData[16] ? "Yes" : "No"}
                          dealerDelinquentLoans={tableMeta.rowData[38] == null ? 0 : tableMeta.rowData[38]}
                          dealerThirtyDayDelinquentLoansCount={tableMeta.rowData[39] == null ? 0 : tableMeta.rowData[39] }
                          />
            }
          }
        },
        {
          name: 'Amount',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Amount') : true,
              customBodyRender: (value, tableMeta) => {
                return tableMeta.rowData[31] ? <Fragment>[BOUNCED] {isDailyProcessingWrite() ? editableCell(value, tableMeta) : value}</Fragment> : isDailyProcessingWrite() ? editableCell(value, tableMeta) : value
              },
              sortCompare: sortDollarColumn
          }
        },
        {
          name: 'Payment Stage',
          options: {
            display: visibleColumns ? visibleColumns.includes('Payment Stage') : true,
            customFilterListOptions: {
              render: v => `${v} payment`,
            },
          }
        },
        {
          name: 'Completed Payments',
          options: {
            display: visibleColumns ? visibleColumns.includes('Completed Payments') : true,
            customBodyRender: (value, tableMeta) => {
              return <PaymentStageCell value={value} alreadyPaidStages={tableMeta.rowData[18]} otherStagesPaidToday={tableMeta.rowData[23]} numberOfPaymentStages={tableMeta.rowData[28]} multiplePayments={tableMeta.rowData[41]} />
            },
            filter: false,
          }
        },
        {
          name: 'CU Pull',
          options: {
            display: visibleColumns ? visibleColumns.includes('CU Pull') : true,
            customBodyRender: isCUPullCell,
            customFilterListOptions: {
              render: v => v ? "CU Pull Fail" : "CU Pull Sucess",
            },
          }
        },
        // {
        //   name: 'Payment Type',
        // },
        {
          name: 'Org',
          options: {
            display: visibleColumns ? visibleColumns.includes('Org') : true,
          }
        },
        {
          name: 'Status',
          options: {
            display: visibleColumns ? visibleColumns.includes('Status') : true,
            customBodyRender: (value, tableMeta) => {
              return value.charAt(0).toUpperCase() + value.slice(1);
            }
          }
        },
        {
          name: 'Notify?',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Notify?') : true,
              customBodyRender: (value, tableMeta) => {
                return (
                  (isDailyProcessingWrite() || isAdmin()) ? 
                    <NotifyCheckboxCell value={value} id={tableMeta.rowData[0]} transactionType={'payment'} date={selectedDate} disableUpdates={false}/> :
                    <NotifyCheckboxCell value={value} id={tableMeta.rowData[0]} transactionType={'payment'} date={selectedDate} disableUpdates={true}/>
                  )
            },
            customFilterListOptions: {
              render: v => v ? "Notify Enabled" : "Notify Disabled",
            },
          }
        },
        {
          name: 'Comments',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Comments') : true,
              customBodyRender: (value, tableMeta) => {
                return (parseInt(value) === 0) ? null : (<CommentPreviewTooltip count={value} comments={tableMeta.rowData[40]}  />)
              }
          }
        },
        {
          name: 'Dealer Account Flag',
          options: {
            display: visibleColumns ? visibleColumns.includes('Dealer Account Flag') : true,
            customBodyRender: dealerNeedsReviewCell,
            customFilterListOptions: {
              render: v => v ? "Dealer Flagged" : "Dealer Not Flagged",
            },
          }
        },
        {
          name: "Manual Flag",
          options: {
            display: visibleColumns ? visibleColumns.includes('Manual Flag') : true,
            customBodyRender: (value, tableMeta) => {
              return ( isDailyProcessingWrite() ? 
                <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[26]} /> :
                <FlagCell value={value} dealerNumber={tableMeta.rowData[2]} flagReason={tableMeta.rowData[26]} disableUpdates={true} />
              );
            },
            customFilterListOptions: {
              render: v => v ? "Manual Flagged" : "Non-Manual Flagged",
            },
          }
        },
        {
          name: 'Lender',
          options: {
            display: visibleColumns ? visibleColumns.includes('Lender') : true,
          }
        },
        {
          name: 'Borrower',
          options: {
            display: visibleColumns ? visibleColumns.includes('Borrower') : true,
          }
        },
        {
          name: 'Concierge Dealer',
          options: {
            filter: false,
            display: visibleColumns ? visibleColumns.includes('Concierge Dealer') : true,
            customBodyRender: isConciergeCell,
          }
        },
        {
          name: 'Notes',
          options: {
              setCellProps: () => ({ style: { minWidth: "250px" }}),
              display: visibleColumns ? visibleColumns.includes('Notes') : true,
              customBodyRender: (value, tableMeta) => {
                return (
                  (isDailyProcessingWrite() || isAdmin()) ? 
                    <EditableTextCell 
                      value={value} 
                      id={tableMeta.rowData[0]} 
                      label={'Payment Notes'} 
                      onSave={LoanService.updatePaymentNotes} /> : value
                  )
            },
            filter: false,
          }
        },
        {
          name: 'Stages Already Paid',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'estimatedProjectAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          // This comes from loan table not FR table
          name: 'hiddenTotalLoanAmount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'term',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'apr',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Other Stages Paid Today',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Coming Off Hold',
          options: {
            display: visibleColumns ? visibleColumns.includes('Coming Off Hold') : true,
            customFilterListOptions: {
              render: v => v === "No" ? "Not Coming off Hold" : "Coming off Hold",
            },
          }
        },
        {
          name: 'Hold Comment',
          options: {
            display: visibleColumns ? visibleColumns.includes('Hold Comment') : true,
            filter: false,
          }
        },
        {
          name: 'Manual Flag Reason',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Manual Flag Reason') : true,
              viewColumns: true
          }
        },
        {
          name: 'Total Funded Loans',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Total Funded Loans') : true,
          }
        },
        {
          name: 'Number Of Payment Stages',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Number Of Payment Stages') : true,
          }
        },
        {
          name: 'Promos',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Promos') : true,
              customBodyRender: (value) => {
                return value.map( (val, key) => {
                    return <Chip label={val} key={key} style={{backgroundColor: promoToColor(val), color: 'white'}} />;
              });
            }
          }
        },
        {
          name: 'Project Type',
          options: {
              filter: false,
              display: visibleColumns ? visibleColumns.includes('Project Type') : true,
          }
        },
        {
          name: 'Bounced',
          options: {
              filter: true,
              display: false,
              viewColumns: false,
              customFilterListOptions: {
                render: v => v ? "Bounced Payments" : "Non Bounced Payments",
              },
          }
        },
        {
          name: 'Loan Type',
          options: {
              filter: true,
              display: visibleColumns ? visibleColumns.includes('Loan Type') : true,
              sort: false,
              viewColumns: true,
              filterOptions: {
                names: ['Home Improvement', 'Healthcare', 'Solar'],
              },
              customBodyRender: (value) => {
                return <LoanTypeIcon loanType={value} />
              },
          }
        },
        {
          name: 'Dealer Max Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Total Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Average Loan Amount',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer First Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Latest Funding Date',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Dealer 30+ Delinquent Loans',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Comment Messages',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        },
        {
          name: 'Multiple Payments',
          options: {
            filter: false,
            display: false,
            viewColumns: false,
          }
        }
      ];

      const options = {
        filterType: 'dropdown',
        print: false,
        searchOpen: true,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50,100,250],
        rowsSelected: selectedRowIndices,
        setRowProps: (row, dataIndex, rowIndex) => {
          if (row[31]) {
            return {
              style: {background: red[200]}
            };
          }
          else if (rowIndex % 2 === 0) {
            return {
              style: { background: 'snow' }
            };
          }
        },
        onRowSelectionChange: (rowsSelected, allRows) => {
          setSelectedRowsIndices(allRows.map(row => row.dataIndex));
        },
        onTableChange: (action, tableState) => {
          switch (action) {
            case 'viewColumnsChange':
              let displayedVisibleColumns = tableState.columns.map(column => {
                if (column.display === "true") {
                  return column.name;
                } else {
                  return null;
                }
              }).filter(column => column !== null);

              if (sessionStorage.getItem("paymentsTable")) {
                saveTableSession(displayedVisibleColumns, "paymentsTable");
              }
              setVisibleColumns(displayedVisibleColumns);

              break;
            default:
              break;
          }
        },
        selectToolbarPlacement: 'none',
        customToolbar: () => {
          const isProcessingPaymentsTable = paymentType === 'processingPayments';
          return isDailyProcessingWrite() ? (
                  <Fragment>
                    <RepullButton hasItems={payments.length > 0} pullName={'Payments'} onClick={toggleConfirmation}/>
                    <Tooltip title={'Add New Payment'}>
                      <IconButton onClick={handleAddPaymentClick}> 
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                      {
                        selectedRowIndices.length > 0 ?
                          <><AddHoldRequestButtons selectedRowIndices={selectedRowIndices} potentialHolds={payments} isFundingRequests={false} excludeRemoveButton={isProcessingPaymentsTable} />
                            {
                              isProcessingPaymentsTable ?
                              <BulkReleaseProcessingPaymentsButton selectedRowIndices={selectedRowIndices} payments={payments} /> :
                              <AddMarkAsProcessingButton selectedRowIndices={selectedRowIndices} payments={payments} />
                            }
                          <DeletePaymentsButton selectedRowIndices={selectedRowIndices} potentialDeletions={payments} date={selectedDate}  /></> : null
                      }
                  </Fragment>
                ) : null;
        },
        download: (isDailyProcessingWrite() ? true : false),
        onDownload: () => {
          LoanService.downloadPaymentsReport(selectedDate).then(data => {
          }).catch(error => {
            createErrorNotification('Failed to Download Payments');
          });
          return false;
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
          return (
            <CustomSearchBar
              searchText={searchText}
              handleSearch={handleSearch}
              hideSearch={hideSearch}
              options={options}
              matchExactText={matchExactText}
              handleMatchExactText={handleMatchExactText}
            />
          );
        },
        customSearch: (searchQuery, currentRow, columns) => {
          return customTableSearch(searchQuery, currentRow, columns, matchExactText);
        },
        onChangeRowsPerPage: (rowNumber) => {
          setRowsPerPage(rowNumber);
        },
      };

      return payments === null ? (
        <h1>Loading...</h1>
      ) : (
        <Fragment>
        <ConfirmationModal title="Confirm" message="Are you sure you want to pull payments?"  showConfirmationBox={showConfirmation} onConfirm={handlePullClick} onCancel={toggleConfirmation}/>
        <MUIDataTable data={tablifyPayments(payments)} columns={columns} options={options} />
        </Fragment>
      );
  }

  export default memo(PaymentsTable);
