import React from 'react';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConciergeBellIcon from '@material-ui/icons/RoomService';
import NonConciergeOutlineIcon from '@material-ui/icons/RoomServiceOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import DelinquencyIcon from '@material-ui/icons/History';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Badge from '@material-ui/core/Badge';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import EditableCell from '../pages/DailyProcessing/EditableCell';

const isHeldCell = value => {
    return value ? <ErrorOutlinedIcon style={{ color: orange[500], pointerEvents: 'none' }} /> : false;
}

const booleanIconCell = value => {
    return value ?
        <CheckCircleIcon style={{ color: green[500] }} /> :
        <ErrorOutlinedIcon style={{ color: red[500] }} />;
}

const dealerNeedsReviewCell = value => {
    return value ? <ErrorOutlinedIcon style={{ color: red[500] }} /> : false;
}

const editableCell = (value, tableMeta) => {
    return <EditableCell value={value} paymentId={tableMeta.rowData[0]} />
}

const isConciergeCell = value => {
    return value ? 
        <ConciergeBellIcon style={{ color: amber[600], pointerEvents: 'none' }} /> :
        <NonConciergeOutlineIcon style={{ color: grey[400], pointerEvents: 'none' }} />;
}

const isDelinquentCell = value => {
    return (value === 0) ? 
        <DelinquencyIcon 
            style = {{ 
                color: grey[400], 
                pointerEvents: 'none'
            }}/>:
        (<Badge 
            color="primary" 
            badgeContent={value} 
            max={999} 
            > 
            <DelinquencyIcon 
                style={{ color: amber[900], pointerEvents: 'none' }}
                className='MuiIcon-colorDisabled' 
            /> 
        </Badge>);   
}

const isRuleFailureCell = value => {
    return value !== 0 ? 
        (<Badge color="primary" badgeContent={value} max={999}><WarningIcon style={{ color: amber[800] }} /> </Badge>)
        : null;
    
}

const isCUPullCell = value => {
    return value ? 
    <ClearIcon style={{fill: red[500]}} /> : 
    <CheckIcon style={{fill: green[500]}} />;
}

export {isHeldCell, booleanIconCell, dealerNeedsReviewCell, editableCell, isConciergeCell, isDelinquentCell, isRuleFailureCell, isCUPullCell};