import React, { memo, useState, Fragment } from 'react';
import { LoanService } from '../../services';
import { useNotification } from '../../contexts/NotificationContext';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmationModal from '../../components/ConfirmationModal'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import LoadingModal from '../../components/LoadingModal';

const ReleaseHoldButton = ({loanNumber, currentDate}) => {
  const [showReleaseHoldsConfirmation, setShowReleaseHoldsConfirmation] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const { createErrorNotification } = useNotification();

  const toggleReleaseHoldsConfirmation = () => setShowReleaseHoldsConfirmation(!showReleaseHoldsConfirmation);
  const openLoadingModal = () => setShowLoadingModal(true);
  const closeLoadingModal = () => setShowLoadingModal(false);
  
      const handleReleaseHoldClick = () => {
        toggleReleaseHoldsConfirmation();
        openLoadingModal();
        LoanService.releaseHolds(loanNumber).then(response => {
          // !FIXME: Do this better!
          window.location.reload();
          closeLoadingModal();
        }).catch(error => {
          createErrorNotification('Failed to release holds');
        });
      }

      return (
        <>
          <Fragment>
              <Tooltip title={currentDate ? 'Instant Release' : 'Can only modify holds for current date'}>
                <IconButton onClick={toggleReleaseHoldsConfirmation}  disabled={!currentDate}>
                  <RemoveCircleOutline />
                </IconButton>
              </Tooltip>
        </Fragment>
        <ConfirmationModal title="Confirm" message="Are you sure you want to release these loans from hold" onConfirm={handleReleaseHoldClick} onCancel={toggleReleaseHoldsConfirmation} showConfirmationBox={showReleaseHoldsConfirmation}/>
        <LoadingModal
            open={showLoadingModal}
          />
        </>
      );
  }

export default memo(ReleaseHoldButton);