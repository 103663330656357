/* eslint eqeqeq: "off" */
import React, { Fragment, memo, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import CustomSearchBar from '../../components/CustomSearchBar';
import { dealerNeedsReviewCell } from '../../utils/cellRenderers';
import { FundingStagesCell, DealerMetricTooltip, BorrowerMetricTooltip, CommentPreviewTooltip } from '../../components';
import { LOAN_HOLD_STATUS } from '../../utils/constants';
import EditableTextCell from './EditableTextCell';
import { LoanService } from '../../services';
import { centsToDollar, formatDate } from '../../utils/formatters';
import { customTableSearch } from '../../utils/tableHelpers.js';
import { useTheme } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ReleaseHoldButton from './ReleaseHoldButton';
import BulkReleaseHoldButton from './BulkReleaseHoldButton';
import CancelHoldButton from './CancelHoldButton';
import { useNotification } from '../../contexts/NotificationContext';
import { useRole } from '../../contexts/RolesContext';

const tablifyHolds = (holds) =>
  holds == null ? null :
  holds.map(hold => {
    return [
      hold.id,
      hold.loanNumber,
      centsToDollar(hold.loanAmount),
      hold.dealer.number,
      hold.dealer.name,
      FundingStagesCell({"hasStagedFunding": hold.hasStagedFunding, "paidStages": hold.paidStages, "heldStages": hold.heldStages, "hasForcedPayment": hold.hasForcedPayment, "numberOfPaymentStages": hold.numberOfPaymentStages}),
      hold.dealer.needsReview,
      hold.lender.shortName,
      hold.hasBeenFunded,
      hold.holdStatus,
      null,
      null,
      null,
      hold.holdDate,
      hold.holdComment,
      hold.commentCount,
      hold.dealer?.isConcierge,
      hold.dealer?.totalFundedLoans || 0,
      hold.dealer?.maxLoanAmount,
      hold.dealer?.totalLoanAmount,
      hold.dealer?.averageLoanAmount,
      hold.dealer?.firstFundingDate,
      hold.dealer?.lastFundingDate,
      hold.dealer?.numberOfDelinquentLoans,
      hold.dealer?.numberOfOverThirtyDayDelinquentLoans,
      hold.applicant?.fullName,
      hold.applicant?.address,
      hold.applicant?.creditScore,
      hold.applicant?.employer,
      hold.applicant?.debtIncomeRatio,
      hold.applicant?.totalIncome,
      hold.coapplicant?.fullName,
      hold.coapplicant?.employer,
      hold.coapplicant?.creditScore,
      hold.comments,
    ];
});

const isToday = (date) => {
  return (new Date(date).toDateString() === new Date().toDateString())
}

const HoldsTable = ({ holds, selectedDate, autoSend}) => {
    const { createErrorNotification } = useNotification();
    const { isDailyProcessingWrite } = useRole();
    const [selectedRowIndices, setSelectedRowsIndices] = useState([]);
    const [matchExactText, setmatchExactText] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleMatchExactText = (event) => {
      setmatchExactText(event.target.checked);
    }

    const handleAddPendingRelease = async (loanNumber) => {
      const response = await LoanService.markPendingRelease(loanNumber);

      if (response.status && response.status === 200) {
        setTimeout(() => window.location.reload(), 500);
      } else {
        createErrorNotification(`Failed to add pending release for loanNumber: ${loanNumber}`);
      }
    }

    const handleRemovePendingRelease = async (loanNumber) => {
      const response = await LoanService.removePendingRelease(loanNumber);

      if (response.status && response.status === 200) {
        setTimeout(() => window.location.reload(), 500);
      } else {
        createErrorNotification(`Failed to remove pending release for loanNumber: ${loanNumber}`);
      }
    }

    useTheme();
    const options = {
      filterType: 'dropdown',
      print: false,
      download: false,
      searchOpen: true,
      rowsSelected: selectedRowIndices,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [50,100,250],
      setRowProps: (row, dataIndex, rowIndex) => {
        if (rowIndex % 2 === 0) {
          return {
            style: { background: 'snow' }
          };
        }
      },
      onRowSelectionChange: (rowsSelected, allRows) => {
        setSelectedRowsIndices(allRows.map(row => row.dataIndex));
      },
      selectToolbarPlacement: 'none',
      customToolbar: () => {
        return isDailyProcessingWrite() ? (
                <Fragment>
                    {
                      selectedRowIndices.length > 0 ?
                      <BulkReleaseHoldButton selectedRowIndices={selectedRowIndices} holds={holds} />  :
                        null
                    }
                </Fragment>
              ) : null;
      },
      customSearchRender: (searchText, handleSearch, hideSearch, options) => {
        return (
          <CustomSearchBar
            searchText={searchText}
            handleSearch={handleSearch}
            hideSearch={hideSearch}
            options={options}
            matchExactText={matchExactText}
            handleMatchExactText={handleMatchExactText}
          />
        );
      },
      customSearch: (searchQuery, currentRow, columns) => {
        return customTableSearch(searchQuery, currentRow, columns, matchExactText);
      },
      onChangeRowsPerPage: (rowNumber) => {
        setRowsPerPage(rowNumber);
      },
    };

    const columns = [
      {
        name: 'Real Loan ID',
        options: {
          filter: false,
          display: false,
        }
      },
      {
        name: 'Loan #',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return <div style={{display: 'flex'}}>
              <a style={{paddingTop:"10px"}} href={`/lms/loans/${value}`}>{value}</a>
              <BorrowerMetricTooltip
                  borrowerName={tableMeta.rowData[25]}
                  borrowerCreditScore={tableMeta.rowData[27]}
                  borrowerDTI={tableMeta.rowData[29]}
                  borrowerAddress={tableMeta.rowData[26]}
                  borrowerEmployer={tableMeta.rowData[28]}
                  borrowerIncome={tableMeta.rowData[30]}
                  coborrowerName={tableMeta.rowData[31]}
                  coborrowerCreditScore={tableMeta.rowData[33]}
                  coborrowerEmployer={tableMeta.rowData[32]}
                />
              </div>
          }
        }
      },
      {
        name: 'Loan Amount',
        options: {
          filter: false,
        }
      },
      {
        name: 'Dealer #',
        options: {
          customBodyRender: (value, tableMeta) => {
            return <a href={`/dealers/${value}`}>{value}</a>
          }
        }
      },
      {
        name: 'Dealer',
        options: {
          customBodyRender: (value, tableMeta) => {
            return <DealerMetricTooltip 
                      dealerName={value}
                      totalFundedLoans={tableMeta.rowData[17]} 
                      maxLoanAmount={centsToDollar(tableMeta.rowData[18])}
                      totalLoanAmount={centsToDollar(tableMeta.rowData[19])}
                      averageLoanAmount={centsToDollar(tableMeta.rowData[20])}
                      firstFundingDate={tableMeta.rowData[21]}
                      lastFundingDate={tableMeta.rowData[22]}
                      dealerConcierge={tableMeta.rowData[16] ? "Yes" : "No"}
                      dealerDelinquentLoans={tableMeta.rowData[23] == null ? 0 : tableMeta.rowData[23]}
                      dealerThirtyDayDelinquentLoansCount={tableMeta.rowData[24] == null ? 0 : tableMeta.rowData[24] }
                      />
          }
        }
      },
      {
        name: 'Dealer Payments',
        options: {
          filter: false,
        }
      },
      {
        name: 'Dealer Account Flag',
        options: {
          customBodyRender: dealerNeedsReviewCell,
          customFilterListOptions: {
            render: v => v ? "Dealer Flagged" : "Dealer Not Flagged",
          },
        }
      },
      {
        name: 'Lender',
      },
      {
        name: 'Funding',
      },
      {
        name: 'Status',
      },
      {
        name: 'Queue Release',
        options: {
          filter: false,
            customBodyRender: (value, tableMeta) => {
              const loanNumber = tableMeta.rowData[1];
              const holdStatus = tableMeta.rowData[9];
              return (
                <Fragment>
                {isDailyProcessingWrite() && [LOAN_HOLD_STATUS.HOLD_FOR_FUNDING, LOAN_HOLD_STATUS.HOLD_FOR_PAYMENT].includes(holdStatus) &&
                  <Tooltip title={isToday(selectedDate) ? 'Add Pending Release' : 'Can only modify holds for current date'}>
                    <IconButton onClick={() => handleAddPendingRelease(loanNumber)} disabled={!isToday(selectedDate)} > 
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                }
                {isDailyProcessingWrite() && [LOAN_HOLD_STATUS.PENDING_RELEASE].includes(holdStatus) &&
                  <Tooltip title={isToday(selectedDate) ? 'Remove Pending Release' : 'Can only modify holds for current date'}>
                    <IconButton onClick={() => handleRemovePendingRelease(loanNumber)} disabled={!isToday(selectedDate)} > 
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                }
                </Fragment>
              )
            }
        }
      },
      {
        name: 'Instant Release',
        options:{
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const loanNumber = tableMeta.rowData[1];
            const holdStatus = tableMeta.rowData[9];
            if(autoSend.sendStatus === 'true')
            {
              return(
                  <Fragment>
                  {[LOAN_HOLD_STATUS.HOLD_FOR_PAYMENT].includes(holdStatus) &&
                  <ReleaseHoldButton loanNumber={loanNumber} currentDate={isToday(selectedDate)}>
                    </ReleaseHoldButton>
                  }
                </Fragment>
              )
            }
            else{
              return( 
                <Fragment>
                  {[LOAN_HOLD_STATUS.HOLD_FOR_FUNDING, LOAN_HOLD_STATUS.HOLD_FOR_PAYMENT].includes(holdStatus) &&
                    <ReleaseHoldButton loanNumber={loanNumber} currentDate={isToday(selectedDate)}>  
                    </ReleaseHoldButton>
                  }
                </Fragment>
              )
            }
          }
        }
      },
      {
        name: 'Cancel Hold',
        options:{
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const loanNumber = tableMeta.rowData[1];
              return(
                <Fragment>
                  <CancelHoldButton loanNumber={loanNumber}>
                  </CancelHoldButton>
                </Fragment>
              )
          }
        }
      },
      {
        name: 'Hold Date',
        options: {
          customBodyRender: (value, tableMeta) => {
            return value ? formatDate(value) : null;
          }
        }
      },
      {
        name: 'Hold Comment',
        options: {
          filter: false,
            customBodyRender: (value, tableMeta) => {
              return <EditableTextCell 
                        value={value} 
                        id={tableMeta.rowData[0]} 
                        label={'Hold Comment'} 
                        onSave={LoanService.updateHoldComment} />
          }
        }
      },
      {
        name: 'Comments',
        options: {
            /*ignore jslint start*/
            customBodyRender: (value, tableMeta) => {
              return (parseInt(value) === 0) ? null : (<CommentPreviewTooltip count={value} comments={tableMeta.rowData[34]}  />)
            }
            /*ignore jslint end*/
        }
      },
      {
        name: 'Dealer Concierge?',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Total Funded Loans',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Max Loan Amount',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Total Loan Amount',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Average Loan Amount',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer First Funding Date',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Latest Funding Date',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer Delinquent Loans',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Dealer 30+ Delinquent Loans',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower Name',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower Address',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower Credit Score',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower Employer',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower DTI',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Borrower Total Income',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Coborrower Name',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Coborrower Employer',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
       name: 'Coborrower Credit Score',
       options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
      {
        name: 'Comment Messages',
        options: {
          filter: false,
          display: false,
          viewColumns: false,
        }
      }
    ];

    return holds === null ? (
      <h1>Loading...</h1>
    ) : (
      <Fragment>
          <MUIDataTable data={tablifyHolds(holds)} columns={columns} options={options} />
      </Fragment>
    );
}

export default memo(HoldsTable);
