import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DateFnsUtils from '@date-io/date-fns';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { LoanService } from '../../services';
import { DealerService } from '../../services';
import { FeeService } from '../../services';
import { formatDate } from '../../utils/formatters';
import FundingRequests from './FundingRequests';
import Payments from './Payments';
import ProcessingPayments from './ProcessingPayments';
import Returns from './Returns';
import Notifications from './Notifications';
import Rules from './Rules';
import PullHistory from './PullHistory';
import Fees from './Fees';
import ConfirmationModal from '../../components/ConfirmationModal'
import LoadingModal from '../../components/LoadingModal';
import Holds from './Holds';
import { useNotification } from '../../contexts/NotificationContext';
import { useRole } from '../../contexts/RolesContext';
import AutoSendFRStatus from '../../components/AutoSendFRStatus';
import SuccessFailModal from '../../components/SuccessFailModal';

const getInitialDate = (props) => {
  const initDate = new URLSearchParams(props.location.search).get("date");

  return initDate ?
    initDate : new Date();
}

const getInitialTab = (props) => {
  const initTab = new URLSearchParams(props.location.search).get("tab");

  return initTab ?
    parseInt(initTab) : 1;
}

const DailyProcessing = (props) => {
    const [selectedDate, setSelectedDate] = useState(getInitialDate(props));
    const [selectedTab, setSelectedTab] = useState(getInitialTab(props));
    const [showNachaSuccess, setShowNachaSuccess] = useState(false);
    const [showNachaError, setShowNachaError] = useState(false);
    const [showNachaReturnsError, setShowNachaReturnsError] = useState(false);
    const [showNachaTrxnsError, setShowNachaTrxnsError] = useState(false);
    const [showNachaFeesError, setShowNachaFeesError] = useState(false);
    const [autoSend, setAutoSend] = useState(false);
    const [showPaymentConfirmationBox, setShowPaymentConfirmationBox] = useState(false);
    const [showNLSFundingConfirmationBox, setShowNLSFundingConfirmationBox] = useState(false);
    const [showNLSPaymentConfirmationBox, setShowNLSPaymentConfirmationBox] = useState(false);

    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [showSuccessFailModal, setShowSuccessFailModal] = useState(false);
    const [modalDialog, setModalDialog] = useState('');
    const [successfulResponse, setSuccessfulResponse] = useState(false);

    const [showFundingReportConfirmationBox, setShowFundingReportConfirmationBox] = useState(false);
    const [nachaError, setNachaError] = useState(null);
    const [nachaReturnsError, setNachaReturnsError] = useState(null);
    const [nachaTrxnsError, setNachaTrxnsError] = useState(null);
    const [nachaFeesError, setNachaFeesError] = useState(null);
    const [anchorNacha, setAnchorNacha] = useState(null);
    const [anchorNLS, setAnchorNLS] = useState(null);
    const [dailyProcessingData, setDailyProcessingData] = useState(null);

    const { createNotification, createErrorNotification } = useNotification();
    const { isDailyProcessingWrite } = useRole();

    const togglePaymentConfirmation = () => setShowPaymentConfirmationBox(!showPaymentConfirmationBox);
    const toggleFundingReportConfirmation = () => setShowFundingReportConfirmationBox(!showFundingReportConfirmationBox);
    const toggleNLSFundingConfirmation = () => setShowNLSFundingConfirmationBox(!showNLSFundingConfirmationBox);
    const toggleNLSPaymentConfirmation = () => setShowNLSPaymentConfirmationBox(!showNLSPaymentConfirmationBox);
    const openLoadingModal = () => setShowLoadingModal(true);
    const closeLoadingModal = () => setShowLoadingModal(false);
    const openSuccessFailModal = () => setShowSuccessFailModal(true);
    const closeSuccessFailModal = () => setShowSuccessFailModal(false);
    const changeDialog = (value) => setModalDialog(value);
    const changeSuccessfulResponse = (value) => setSuccessfulResponse(value);

    const toggleNLSFundingConfirmationAndCloseMenu = () => {
      handleNLSMenuClose();
      setShowNLSFundingConfirmationBox(!showNLSFundingConfirmationBox);
    }

    const toggleNLSPaymentConfirmationAndCloseMenu = () => {
      handleNLSMenuClose();
      setShowNLSPaymentConfirmationBox(!showNLSPaymentConfirmationBox);
    }

    const handleDateChange = (date) => {
      try {
        let formattedDate = formatDate(date);
        if(formattedDate !== 'Invalid Date') {
          setSearchParam('date', formattedDate);
          setSelectedDate(date);
        }
      } catch (error) {
        createErrorNotification(error);
      }
    }

    const handleTabSelect = (event, newValue) => {
      setSearchParam('tab', newValue);

      setSelectedTab(newValue);
    }

    const setSearchParam = (param, value) => {
      let searchParams = new URLSearchParams(props.location.search);
      searchParams.set(param, value);

      props.history.push({
        pathname: '/lms/daily-processing',
        search: "?" + searchParams.toString()
      });
    }

    const handleExportNachaClick = (event) => {
      setAnchorNacha(event.currentTarget);
    }

    const handleNachaClose = () => {
      setAnchorNacha(null);
    }

    const handleNLSMenuClick = (event) => {
      setAnchorNLS(event.currentTarget);
    }

    const handleNLSMenuClose = () => {
      setAnchorNLS(null);
    }

    // Context wasn't updating correctly for this, maybe because of the file download?
    const handleAllNachaExport = (event) => {
      handleNachaClose();
      Promise.all([LoanService.downloadNachaFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaError(error.response?.data?.error);
        setShowNachaError(true);
      }), LoanService.downloadNachaReturnsFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaReturnsError(error.response?.data?.error);
        setShowNachaReturnsError(true);
      }), DealerService.downloadNachaTrxnFile().then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaTrxnsError(error.response?.data?.error);
        setShowNachaTrxnsError(true);
      }), FeeService.downloadNachaFeeFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaFeesError(error.response?.data?.error);
        setShowNachaFeesError(true);
      })]).then();
    }

    const handleReturnsNachaExport = (event) => {
      handleNachaClose();
      Promise.all([LoanService.downloadNachaReturnsFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaReturnsError(error.response?.data?.error);
        setShowNachaReturnsError(true);
      })]).then();
    }

    const handleTestTrxnsNachaExport = (event) => {
      handleNachaClose();
      Promise.all([DealerService.downloadNachaTrxnFile().then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaTrxnsError(error.response?.data?.error);
        setShowNachaTrxnsError(true);
      })]).then();
    }

    const handleFeesNachaExport = (event) => {
      handleNachaClose();
      Promise.all([FeeService.downloadNachaFeeFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaFeesError(error.response?.data?.error);
        setShowNachaFeesError(true);
      })]).then();
    }

    const handleNachaExport = (event) => {
      handleNachaClose();
      Promise.all([LoanService.downloadNachaFile(selectedDate).then(data => {
        setShowNachaSuccess(true);
      }).catch(error => {
        setNachaError(error.response?.data?.error);
        setShowNachaError(true);
      })]).then();
    }

    const handleSendNotificationsClick = (event) => {
      togglePaymentConfirmation();
      LoanService.sendDailyProcessingNotifications(selectedDate).then(response => {
        if (response.status === 200) {
          createNotification('Daily Payment Notifications Sent');
        } else {
          createErrorNotification('Failed to Send Daily Payment Notifications');
        }
      }).catch(error => {
        createErrorNotification('Failed to Send Daily Payment Notifications');
      });
    }

    const handleSendNLSFundingUpdateClick = (event) => {
      toggleNLSFundingConfirmationAndCloseMenu();
      openLoadingModal();
      LoanService.sendNLSFundingUpdate(selectedDate).then(response => {
        if (response.status === 200) {
          changeSuccessfulResponse(true);
          closeLoadingModal();
          changeDialog('Successfully updated NLS!');
          openSuccessFailModal();
        }
      }).catch(error => {
        LoanService.getSendNLSFundingUpdateError(selectedDate, error.response?.data.errID).then(response => {
          if (response.status === 200){
            const errorDialog = `${response.data.errorDetails.operation}: ${response.data.errorDetails.operationStatus}`;
            changeDialog(errorDialog);
          }
        }).catch(error => {
          changeDialog('Unable to update NLS.');
        });

        changeSuccessfulResponse(false);
        closeLoadingModal();
        openSuccessFailModal();
      });
    }

    const handleSendNLSPaymentUpdateClick = (event) => {
      toggleNLSPaymentConfirmationAndCloseMenu();
      openLoadingModal();
      LoanService.sendNLSPaymentUpdate(selectedDate).then(response => {
        if (response.status === 200) {
          changeSuccessfulResponse(true);
          closeLoadingModal();
          changeDialog('Successfully updated NLS!');
          openSuccessFailModal();
        }
      }).catch(error => {
        LoanService.getSendNLSPaymentUpdateError(selectedDate, error.response?.data.errID).then(response => {
          if (response.status === 200){
            const errorDialog = `${response.data.errorDetails.operation}: ${response.data.errorDetails.operationStatus}`;
            changeDialog(errorDialog);
          }
        }).catch(error => {
          changeDialog('Unable to update NLS.');
        });

        changeSuccessfulResponse(false);
        closeLoadingModal();
        openSuccessFailModal();
      });
    }

    const handleDownloadNLSUpdateClick = (event) => {
      handleNLSMenuClose();
      LoanService.downloadNLSFundingXML(selectedDate).then(response => {
        if (response.status === 200) {
          createNotification('Successfully downloaded NLS XML!');
        } else {
          createErrorNotification('Failed to download NLS XML...');
        }
      }).catch(error => {
        createErrorNotification('Failed to download NLS XML...');
      });
      LoanService.downloadNLSPaymentXML(selectedDate).then(response => {
        if (response.status === 200) {
          createNotification('Successfully downloaded NLS XML!');
        } else {
          createErrorNotification('Failed to download NLS XML...');
        }
      }).catch(error => {
        createErrorNotification('Failed to download NLS XML...');
      });
    }

    const handleFundingReportClick = (event) => {
      LoanService.sendDailyFundingReport(selectedDate).then(response => {
        if (response.status === 200) {
          createNotification('Daily Funding Report Sent');
          setAutoSend({sendStatus: 'true', toggleAutoSend: 'true'});
          LoanService.setAutoSendStatus('true', 'true');
        } else {
          createErrorNotification('Failed to Send Daily Funding Report');
        }
      }).catch(error => {
        createErrorNotification('Failed to Send Daily Funding Report');
      });
    }

    const handleAutoSendClick= () => {
      toggleFundingReportConfirmation();
      setAutoSend((autoSend) => { return {sendStatus: autoSend.sendStatus, toggleAutoSend: autoSend.toggleAutoSend === 'true' ? 'false' : 'true'}});
      autoSend.toggleAutoSend === 'true' ? LoanService.setAutoSendStatus(autoSend.sendStatus, 'false') : LoanService.setAutoSendStatus(autoSend.sendStatus, 'true');
    }

    const closeNachaSuccess = () => {
      setShowNachaSuccess(false);
    }

    const closeNachaError = () => {
      setNachaError(null);
      setShowNachaError(false);
    }

    const closeNachaReturnsError = () => {
      setNachaReturnsError(null);
      setShowNachaReturnsError(false);
    }

    const closeNachaTrxnsError = () => {
      setNachaTrxnsError(null);
      setShowNachaTrxnsError(false);
    }

    const closeNachaFeesError = () => {
      setNachaFeesError(null);
      setShowNachaFeesError(false);
    }

    useEffect(() => {
        setShowLoadingModal(true)
        LoanService.getDailyProcessing(selectedDate).then(data => {
          setDailyProcessingData(data);
          setShowLoadingModal(false);
        });
        LoanService.getAutoSendStatus().then(data => {
          setAutoSend(data);
        });
    }, [selectedDate]);

    return (
        <div id="daily-processing-container">
          <h1>Daily Processing</h1>
          <Snackbar open={showNachaSuccess} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeNachaSuccess} severity="success">
              Successfully downloaded Nacha file!
            </Alert>
          </Snackbar>
          <Snackbar open={showNachaError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeNachaError} severity="error">
              {`Failed to download Nacha file...'${nachaError}'`}
            </Alert>
          </Snackbar>
          <Snackbar open={showNachaReturnsError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeNachaReturnsError} severity="error">
              {`Failed to download Nacha returns file...'${nachaReturnsError}'`}
            </Alert>
          </Snackbar>
          <Snackbar open={showNachaTrxnsError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeNachaTrxnsError} severity="error">
              {`Failed to download Nacha test trxns file...'${nachaTrxnsError}'`}
            </Alert>
          </Snackbar>
          <Snackbar open={showNachaFeesError} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={closeNachaFeesError} severity="error">
              {`Failed to download Nacha fees file...'${nachaFeesError}'`}
            </Alert>
          </Snackbar>
          <ConfirmationModal title="Confirm" message="Are you sure you want to send payment notifications?" onConfirm={handleSendNotificationsClick} onCancel={togglePaymentConfirmation} showConfirmationBox={showPaymentConfirmationBox}/>
          <ConfirmationModal title="Confirm" message="Are you sure you want to send NLS FUNDING REQUEST updates?" onConfirm={handleSendNLSFundingUpdateClick} onCancel={toggleNLSFundingConfirmationAndCloseMenu} showConfirmationBox={showNLSFundingConfirmationBox}/>
          <ConfirmationModal title="Confirm" message="Are you sure you want to send NLS PAYMENT/RETURN updates?" onConfirm={handleSendNLSPaymentUpdateClick} onCancel={toggleNLSPaymentConfirmationAndCloseMenu} showConfirmationBox={showNLSPaymentConfirmationBox}/>
          <ConfirmationModal title="Confirm" message="Are you sure you want to enable/disable auto-send funding reports?" onConfirm={handleAutoSendClick} onCancel={toggleFundingReportConfirmation} showConfirmationBox={showFundingReportConfirmationBox}/>
          <LoadingModal
            open={showLoadingModal}
          />

          <SuccessFailModal
            success={successfulResponse}
            open={showSuccessFailModal}
            dialog={modalDialog}
            onClose={closeSuccessFailModal}
          />

          <Grid container spacing={2} justifyContent="flex-end" direction="row" >
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date picker dialog"
                        format="MM/dd/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                </MuiPickersUtilsProvider>
              </Grid>
            {/* TODO: Do we even need a button or just automate? */}
            {isDailyProcessingWrite() ? (<Grid item>
                <Button variant="contained" color="warning" onClick={handleExportNachaClick} target="_blank" endIcon={<ArrowDropDownIcon/>}>
                    Export Nacha File
                </Button>
                <Menu
                  anchorEl={anchorNacha}
                  open={Boolean(anchorNacha)}
                  onClose={handleNachaClose}>
                  <MenuItem onClick={handleNachaExport}>Payments</MenuItem>
                  <MenuItem onClick={handleReturnsNachaExport}>Returns</MenuItem>
                  <MenuItem onClick={handleTestTrxnsNachaExport}>Test Trxns</MenuItem>
                  <MenuItem onClick={handleFeesNachaExport}>Fees</MenuItem>
                  <MenuItem onClick={handleAllNachaExport}>{`All NACHAs`}</MenuItem>
                </Menu>
            </Grid>) : null}
            {isDailyProcessingWrite() ? (<Grid item>
              <Button variant="contained" color="warning" onClick={togglePaymentConfirmation}>
                    Send Notifications
              </Button>
            </Grid>) : null}
            {isDailyProcessingWrite() ? (<Grid item>
                <Button variant="contained" color="warning" onClick={handleNLSMenuClick} target="_blank" endIcon={<ArrowDropDownIcon/>}>
                    NLS
                </Button>
                <Menu
                  anchorEl={anchorNLS}
                  open={Boolean(anchorNLS)}
                  onClose={handleNLSMenuClose}>
                  <MenuItem onClick={toggleNLSFundingConfirmation}>Send Funding Requests to NLS</MenuItem>
                  <MenuItem onClick={toggleNLSPaymentConfirmation}>Send Payments/Returns to NLS</MenuItem>
                  <MenuItem onClick={handleDownloadNLSUpdateClick}>Download NLS XMLs</MenuItem>
                </Menu>
            </Grid>) : null}
            {isDailyProcessingWrite() && autoSend.toggleAutoSend === 'false' ? (<Grid item>
                <Button variant="contained" color="warning" onClick={handleFundingReportClick}>
                    Send Funding Report
                </Button>
            </Grid>) : null }
            {isDailyProcessingWrite() ? (<Grid item>
              <AutoSendFRStatus autoSend={autoSend} title="AUTO-SEND FUNDING REPORT" altTitle="FUNDING REPORT SENT" onClick={toggleFundingReportConfirmation}/>
            </Grid>) : null }
          </Grid>
          <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabSelect}
                aria-label="disabled tabs example"
            >
                <Tab label="Holds" />
                <Tab label="Processing Payments" />
                <Tab label="Funding Requests" />
                <Tab label="Payments" />
                <Tab label="Returns" />
                <Tab label="Notifications" />
                <Tab label="Rules" />
                <Tab label="Automation History" />
                <Tab label="Fees" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                <Holds selectedDate={selectedDate}  autoSend={autoSend} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <ProcessingPayments dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <FundingRequests dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                <Payments dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
                <Returns dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={5}>
                <Notifications dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={6}>
                <Rules dailyProcessingData={dailyProcessingData} selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={7}>
                <PullHistory selectedDate={selectedDate} />
            </TabPanel>
            <TabPanel value={selectedTab} index={8}>
                <Fees selectedDate={selectedDate} />
            </TabPanel>
          </Paper>
        </div>
    );

}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default withRouter(DailyProcessing);
