import React, { useState } from 'react';
import { formatDate } from '../../utils/formatters';
import { numericRegex, dollarAmountRegex, isBlank, updateRequiredField } from '../../utils/dailyPaymentFormHelpers';
import { LoanService } from '../../services';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useNotification } from '../../contexts/NotificationContext';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    left: "30%",
    top: "15%",
    width: '40vw',
    height: '50vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
  },
  modalButton: {
    marginLeft: '15px',
    marginTop: '15px',
    float: 'right',
  },
  coreForm: {
    height: '50vh'
  },
  toggleSwitchElement: {
    display: 'inline',
  }
}));

const NewReturn = (props) => {
  const classes = useStyles();
  const [loanNumber, setLoanNumber] = useState(null);
  const [loanNumberError, setLoanNumberError] = useState(false);
  const [amount, setAmount] = useState(null);
  const [amountError, setAmountError] = useState(false);
  const { createErrorNotification } = useNotification();
  const [princReduc, setPrincReduc] = useState(true);
  const [refundType, setRefundType] = useState('full');

  const resetState = () => {
    setLoanNumber(null);
    setAmount(null);
  }

  const handlePrincReducToggle = () => {
    setPrincReduc(!princReduc);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // First check for missing required fields
    let hasMissingRequiredFields = false;
    hasMissingRequiredFields = updateRequiredField(loanNumber, setLoanNumber, setLoanNumberError) || hasMissingRequiredFields;
    hasMissingRequiredFields = updateRequiredField(amount, setAmount, setAmountError) || hasMissingRequiredFields;
    console.log(hasMissingRequiredFields); // esLint Hack

    if (loanNumberError || isBlank(loanNumber)) {
      createErrorNotification("Invalid loan number. Must be in format '12345'");
    } else if (amountError || isBlank(amount)) {
      createErrorNotification("Invalid Amount. Must be in format '123' or '123.45");
    } else {
      // ? Note: Sent in dollars
      const response = await LoanService.newReturn({
        loanNumber: ('PP' + loanNumber),
        amount: amount,
        reportDate: formatDate(props.selectedDate),
        principalReduction: princReduc,
        refundType: refundType,
      });

      if (response.status && response.status === 200) {
        resetState();
        props.toggleShowNewReturn();
        // TODO: Improve me
        setTimeout(() => window.location.reload(), 500)
      } else {
        createErrorNotification("Failed to save new return");
      }
    }
  }

  const handleCancel = () => {
    props.toggleShowNewReturn();
    resetState();
  }

  const handleLoanNumberChange = (event) => {
    if (numericRegex.test(event.target.value)) {
      setLoanNumberError(false);
      setLoanNumber(event.target.value);
    } else {
      setLoanNumberError(true);
    }
  }

  const handleAmountChange = (event) => {
    if (dollarAmountRegex.test(event.target.value)) {
      setAmountError(false);
      setAmount(event.target.value);
    } else {
      setAmountError(true);
    }
  }

  const handleRefundTypeChange = (event) => {
    setRefundType(event.target.value);
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Create Return</h2>
      <form onSubmit={handleSubmit}>
        <TextField label="Loan Number" inputProps={{ maxLength: 10 }} InputProps={{startAdornment: <InputAdornment position="start">PP</InputAdornment>,}} autoFocus={true} required={true} error={loanNumberError} onChange={handleLoanNumberChange} />
        <TextField label="Amount" inputProps={{ maxLength: 9 }} InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}} fullWidth={true} margin="normal" required={true} error={amountError} onChange={handleAmountChange} />
        <FormLabel id="radio-buttons-group-label">Refund Type</FormLabel>
        <RadioGroup
          label="Refund Type"
          defaultValue="full"
          name="radio-buttons-group"
          value={refundType}
          onChange={handleRefundTypeChange}
        >
          <FormControlLabel value="full" control={<Radio />} label="Full" />
          <FormControlLabel value="partial" control={<Radio />} label="Partial" />
        </RadioGroup>
        <Typography className={classes.toggleSwitchElement}>Principal Reduction</Typography>
        <Tooltip title={<span className={classes.tooltip}><b>Active: </b>Return will generate NLS XML initiating a principal reduction as well as a NACHA entry.<br /><b>Inactive: </b>Return will only generate a NACHA entry.</span>}>
          <Switch checked={princReduc} onChange={handlePrincReducToggle} color="primary"/>
        </Tooltip>
        <Grid container spacing={5} justifyContent="flex-start">
          <Grid item xs={12}>
            <Button variant="contained" color="primary" className={classes.modalButton} onClick={handleSubmit}>Create</Button>
            <Button variant="contained" className={classes.modalButton} onClick={handleCancel}>Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.showNewReturn}
        onClose={props.toggleShowNewReturn}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default NewReturn;