import React from 'react';
import { LoanService } from '../services';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { centsToDollar, formatDate} from '../utils/formatters';
import { makeStyles } from '@material-ui/core/styles';
import { useNotification } from '../contexts/NotificationContext';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const styles = makeStyles(() => ({
  tableRow: {
      "&:hover": {
        backgroundColor: `${blue[50]} !important`,
        cursor: "pointer",
      }
  },
  tableRowBounced: {
    backgroundColor: red[200],
    "&:hover": {
      backgroundColor: `${red[200]} !important`,
      cursor: "pointer",
    }
},
}));

const BounceMarkerDialog = (props) => {
  const classes = styles();
  const { createErrorNotification } = useNotification();

  const handlePaymentClick = async (transactionId, currentlyBounced, type) => {
    const isReturn = type === "Return";
    LoanService.togglePaymentBounce(transactionId, !currentlyBounced, isReturn)
      .then(res => {
        if(res.status === 200) {
          props.onCancel();
          //improve me
          setTimeout(() => window.location.reload(), 100);
        } else {
          props.onCancel();
          createErrorNotification(`Failed to mark bounce, please contact support`);
        }
      }).catch(err => {
        console.error(err);
      })
};

  return (
    <div>
        <Dialog
          open={props.showBounceDialog}
          onClose={props.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.message}
            </DialogContentText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><b>Date</b></TableCell>
                    <TableCell><b>Type</b></TableCell>
                    <TableCell><b>Stage</b></TableCell>
                    <TableCell><b>Amount</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.loanPaymentHistory ? props.loanPaymentHistory.filter(historyItem => historyItem.type === "Payment" || historyItem.type === "Return").sort((a,b) => a.date > b.date ? 1 : -1).map((historyItem) => {
                    return <React.Fragment><TableRow hover className={historyItem.bounced ? classes.tableRowBounced : classes.tableRow} onClick={() => handlePaymentClick(historyItem.transactionID, historyItem.bounced, historyItem.type)}>
                      <TableCell>{formatDate(historyItem.date)}</TableCell>
                      <TableCell>{historyItem.type}</TableCell>
                      <TableCell>{historyItem.type === "Payment" ? historyItem.paymentStage : null}</TableCell>
                      <TableCell>{centsToDollar(historyItem.amount)}</TableCell>
                  </TableRow></React.Fragment>
                  }) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default BounceMarkerDialog;