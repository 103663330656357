import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';

export const formatDate = (date) => {
  return hasTimeComponent(date) ? 
    new Date(date).toLocaleDateString(
        'en',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'America/New_York',

        }
    ) : 
    new Date(date).toLocaleDateString(
        'en',
        {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        }
    );
}

export const getCurrentDateFormatted = () => {
  return formatDate(new Date());
}

export const formatTime = (date) => {
  return new Date(date).toLocaleTimeString(
      'en',
      {
        timeZone: 'America/New_York',
      }
    )
}

const hasTimeComponent = (dateString) => {
  const isoDateTimeRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z?)$/;
  return isoDateTimeRegex.test(dateString);
}

export const formatDateTime = (date) => {
  return new Date(date).toLocaleDateString(
      'en',
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'America/New_York',
      }
    )
}

export const lastFourDigits = (sensitiveString) => {
  if(sensitiveString && sensitiveString.length >= 4) {
    return '#'.repeat(sensitiveString.length - 4) + sensitiveString.slice(-4);
  }
  else {
    return '##error##';
  }
}

export const centsToDollar = (cents) => {
    return `$${(cents / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const aprToPercent = (apr) => {
    return `${parseFloat(apr).toFixed(2)}%`
}

export const sortDollarColumn = (order) => {
  return (obj1, obj2) => {
    let val1 = parseFloat(obj1.data.replace(/[$,]+/g,""));
    let val2 = parseFloat(obj2.data.replace(/[$,]+/g,""));
    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };
}

export const formatCreditScore = (creditScorePB, creditScoreCB) => {
  if (creditScorePB && creditScoreCB) {
    return `${creditScorePB}, ${creditScoreCB}`
  }
  else {
    return creditScorePB
  }
}

export const formatCodes = (codes, isAdmin) => {
  if(codes) {
    let codeArray = codes.split(", ");
    codeArray.forEach(function(code, i) { 
      if (code === 'PIF') codeArray[i] = 'PAID IN FULL'; 
      else if (code === 'INCR') codeArray[i] = 'LOAN INCREASE';
      else if (code === 'DECR') codeArray[i] = 'LOAN DECREASE';
      else if (code === 'PIC') codeArray[i] = 'CANCEL';
    });

    const showP4 = ['CURRENT', 'AUTO PAY', 'PAID IN FULL', 'LOW BALANCE', 'SPANISH', 'NOCALL', 'NOCOMM', 'CLAIM FILED', 'NO BILLING', 'LOAN INCREASE', 'LOAN DECREASE', 'LAM', 'BANKRUPTCY', 'BORROWER DECEASED', 'LAM COMPLETE', 'CANCEL', 'DO NOT REPORT', 'CBR DELETE'];
    const showAdmin = [];
    return codeArray.filter(x => showP4.includes(x)).concat((isAdmin ? codeArray.filter(x => showAdmin.includes(x)) : []));
  }
  return [];
}

export const serverSidePaginationSearchParams = (limit, offset, sortOrder, searchText, filterList) => { 
  return `?limit=${limit}&offset=${offset}` +
      `${sortOrder ? `&sortName=${encodeURIComponent(sortOrder?.name)}` : ''}` +
      `${sortOrder ? `&sortDirection=${encodeURIComponent(sortOrder?.direction)}` : ''}` +
      `${searchText ? `&q=${encodeURIComponent(searchText)}` : ''}` +
      `${loanFilters2SearchParam(filterList)}`
}

export const promoToColor = (promo) => {
  let color;

  switch(promo) {
    case 'BalPay':
      color = pink[400];
      break;
    case 'OHNO':
      color = lightBlue[400];
      break;
    case 'StipPay':
      color = deepPurple[400];
      break;
    case 'APR Buy Down':
      color = indigo[400];
      break;
    case 'Staged Funding':
      color = teal[400];
      break;
    case 'POP':
      color = red[400];
      break;
    default:
      color = teal[400];
      break;
  }

  return color;
}

export const promosToChips = (loan) => {
  return [
    ...(loan.hasStagedFunding ? ['Staged Funding'] : []),
    ...(loan.hasBuyDown ? ['APR Buy Down'] : []),
    ...(loan.hasStipPay ? ['StipPay'] : []),
    ...(loan.hasBalPay ? ['BalPay'] : []),
    ...(loan.hasOHNO ? ['OHNO'] : []),
    ...(loan.hasPOP ? ['POP'] : []),
  ]
}
const loanFilters2SearchParam = (filterList) => {
  if (!filterList) {
    return '';
  }

  let filterSearchParams = '';

  for(let i = 0; i < filterList.length; i++) {
    const filter = filterList[i];
    if (i === 1) {
      if (filter.length > 0) {
        filterSearchParams += `&statusFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    } else if (i === 4) {
      if (filter.length > 0) {
        filterSearchParams += `&aprFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    } else if (i === 5) {
      if (filter.length > 0) {
        filterSearchParams += `&termFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    } else if (i === 8) {
      if (filter.length > 0) {
        filterSearchParams += `&dealerFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    } else if (i === 10) {
      if (filter.length > 0) {
        filterSearchParams += `&orgFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    } else if (i === 11) {
      if (filter.length > 0) {
        filterSearchParams += `&lenderFilters=${encodeURIComponent(filter.join('|'))}`;
      }      
    } 
    else if (i === 15) {
      if(filter.length > 0) {
        filterSearchParams += `&dealerNumberFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 17) {
      if (filter.length > 0) {
        filterSearchParams += `&promoFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 20) {
      if (filter.length > 0) {
        filterSearchParams += `&conciergeFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 21) {
      if (filter.length > 0) {
        filterSearchParams += `&delinquencyFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 22) {
      if (filter.length > 0) {
        filterSearchParams += `&heldFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 23) {
      if (filter.length > 0) {
        filterSearchParams += `&stagedFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 24) {
      if (filter.length > 0) {
        filterSearchParams += `&visionFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 25) {
      if (filter.length > 0) {
        filterSearchParams += `&loanTypeFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 26) {
      if (filter.length > 0) {
        filterSearchParams += `&returnIssuedFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 27) {
      if (filter.length > 0) {
        filterSearchParams += `&statusCodeFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
    else if (i === 29) {
      if (filter.length > 0) {
        filterSearchParams += `&portfolioFilters=${encodeURIComponent(filter.join('|'))}`;
      }
    }
  };

  return filterSearchParams;
}

/* TIME AGO */
const epochs = [
  ['year', 31536000],
  ['month', 2592000],
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1]
];

const getDuration = (timeAgoInSeconds) => {
  for (let [name, seconds] of epochs) {
      const interval = Math.floor(timeAgoInSeconds / seconds);
      if (interval >= 1) {
          return {
              interval: interval,
              epoch: name
          };
      }
  }
};

export const timeAgo = (date) => {
  const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
  if (timeAgoInSeconds > 0) {
    const {interval, epoch} = getDuration(timeAgoInSeconds);
    const suffix = interval === 1 ? '' : 's';
    return `${interval} ${epoch}${suffix} ago`;
  } else {
    return 'now'
  }
};

/* END TIME AGO */

export const formatCountdown = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let secondsLeft = seconds % 60;
  let secondsFormatted = secondsLeft < 10 ? `0` + secondsLeft : secondsLeft
  return `${minutes}:${secondsFormatted}`
};

export const parseCentsToDisplay = (cents) => {
  return parseInt(cents) ? centsToDollar(cents) : '$0';
}