import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FundingStagesCell from './FundingStagesCell';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import red from '@material-ui/core/colors/red';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

const checkForSkippedStage = (currentStage, alreadyPaidStages, otherStagesPaidToday, numberOfPaymentStages, multiplePayments) => {
    if (multiplePayments) {
        return true;
    }

    if (currentStage === 'second' && ((!alreadyPaidStages.includes('first') && !otherStagesPaidToday.includes('first')))) {
        // Second stage, make sure we already paid first stage
        return true;
    } else if (currentStage === 'final' && ((!alreadyPaidStages.includes('first') && !otherStagesPaidToday.includes('first')) || (numberOfPaymentStages === 3 && !alreadyPaidStages.includes('second') && !otherStagesPaidToday.includes('second')))) {
        // Final stage, make sure we already paid first and second stage and that we didn't already pay final stage
        return true;
    } else {
        return false;
    }
}

const sortOrder = ['unstaged','first','second','final'];
const customSort = (arr) => arr.sort(function(a,b) {
    return sortOrder.indexOf( a ) - sortOrder.indexOf( b );
});

const PaymentStageCell = (props) => {
    const {value, alreadyPaidStages, otherStagesPaidToday, numberOfPaymentStages, multiplePayments} = props;
    return checkForSkippedStage(value, alreadyPaidStages, otherStagesPaidToday, numberOfPaymentStages, multiplePayments) ?
        (
          <div style={{display:'flex'}}>
            <FundingStagesCell hasStagedFunding={(value.toLowerCase() === 'unstaged') ? false : true} paidStages={alreadyPaidStages} heldStages={[]} numberOfPaymentStages={numberOfPaymentStages} />
            <HtmlTooltip
                title={
                <React.Fragment>
                    <Typography color="inherit"><b><u>Completed Stage Payments:</u></b></Typography>
                    <p>{customSort(alreadyPaidStages).join(', ')}</p>
                    <p>There's multiple payments for a given stage(s) or a stage had it's payment skipped.</p>
                </React.Fragment>
                }
            >
                <IconButton component="span">
                    <WarningRoundedIcon style={{ color: red[500] }} />
                </IconButton>
            </HtmlTooltip>
          </div>
      ) :
      (
        <HtmlTooltip
            title={
            <React.Fragment>
                <Typography color="inherit"><b><u>Completed Stage Payments:</u></b></Typography>
                <p>{customSort(alreadyPaidStages).join(', ')}</p>
            </React.Fragment>
            }
        >
            <Button><FundingStagesCell hasStagedFunding={value.toLowerCase() !== 'unstaged'} paidStages={alreadyPaidStages} heldStages={[]} numberOfPaymentStages={numberOfPaymentStages} /></Button>
        </HtmlTooltip>
      )
}

export default PaymentStageCell;